.home-container {
  display: flex;
}

.sidebar {
  width: 200px;
  background-color: #2c3e50;
  color: white;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  padding: 15px 20px;
}

.sidebar ul li a,
.sidebar ul li button {
  color: white;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
}

.sidebar ul li a:hover,
.sidebar ul li button:hover {
  background-color: #34495e;
}

.content {
  margin-left: 200px;
  padding: 20px;
  width: calc(100% - 200px);
}
