.expenses-container {
  padding: 20px;
  flex-shrink: 0;
}

.page-header {
  display: flex;
  width: 100%;
  /* height: fit-content; */
  flex-direction: row;
  /* justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    vertical-align: top; */
  /* align-items: normal; */
  /* margin-bottom: 20px; */
  vertical-align: auto;
}

.page-header h1 {
  /* margin: 0; */
  /* width: 70%; */
}

.start-report-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  /* align-self: flex-end; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  width: '100px';
  height: 50%;
}

.start-report-button:hover {
  background-color: #45a049;
}

.expenses-table {
  width: 1200px;
  border-collapse: collapse;
  margin-top: 20px;
}

.expenses-table th,
.expenses-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.expenses-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.expenses-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.expenses-table tr:hover {
  background-color: #f1f1f1;
}

.full-width {
  width: 1200px;
}
