body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.login-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
}

.password-input {
  position: relative;
}

.password-input input {
  padding-right: 70px;
}

.toggle-password {
  position: absolute;
  left: 80%;
  top: 0;
  height: 100%;
  width: 20%;
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 0 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.login-button:hover {
  background-color: #0056b3;
}

.error {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 8px 12px;
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  align-items: center;
}

.error::before {
  content: '⚠';
  margin-right: 8px;
  font-size: 16px;
}
